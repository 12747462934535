<template>
  <div id="app">
	<test></test>
	<div class="banner">
		<img src="./images/banner_02.png" />
	</div>
	
	<div class="recruit">
		<div class="title">
			<h3>社会招聘</h3>
			<img src="./images/zp_18.png" />
		</div>
		<div class="search">
			<input type="text" placeholder="请输入要搜索的职位" v-model="search" />
			<button @click="sears()">搜索职位</button>
		</div>
		<ul>
			<li v-for="item in list" @click="detail(item.id)">
				<h4>{{item.contentTitle}}<span>新</span></h4>
				<!-- <p>技术岗</p> -->
				<p>{{item.contentDescription}}</p>
			</li>
			<div style="margin-left: 47%;margin-bottom: 50px;" v-if="list.length==0">暂无数据</div>
			
		</ul>
	</div>
	<foot></foot>
  </div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
  data() {
	return {
	  inActive: false,
	  list:[],
	  search:''
	}
  },
  created(){
	if (location.href.indexOf("#reloaded") == -1) {
		  location.href = location.href + "#reloaded";
		  location.reload();
	}
	var that = this
	this.$axios.post('/cms/returnAll/officialWebsite')
	.then(function (res) {
		console.log(res);
		 that.list = res.data.categoryList[4].contentList
	})
  
  },
  methods: {
	sears(){
		var that = this
		  this.$axios.post('/cms/search/officialWebsite',{search:this.search}).then(function(res){
			  console.log(res)
			  that.list = res.data.contentList
		  })
	},
	detail(id){
		window.console.log("查询成功",id);
		 // this.$router.push("/recruit?id=" + id);
		 this.$router.push({
		     path: '/recruit',
		     query: {
		       id: id,
		     }

		})
	},
}

}
</script>

<style>

	.banner{

		width: 100%;
	}
	.banner img{
		width: 100%;
	}
	.recruit {

		width: 65%;
		margin: 20px auto;
		color: #333;
	}
	.recruit ul{
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
	}
	.recruit ul li{
		border: 1px solid #eaeaea;
		padding: 20px;
		width: 28%;
		margin-bottom:3%;
	}
	.recruit ul li p{
		font-size: 14px;
		color: #707070;
		margin-bottom: 5px;
	}
	.recruit ul li h4{
		color: #3b3b3b;
		margin-bottom: 30px;
	}
	.recruit ul li h4 span{
		background-color: #EA0029;
		line-height: 1.5;
		color: #fff;
		border-radius: 5px;
		padding: 2px 5px;
		font-size: 12px;
		font-weight: normal;
		margin-left: 10px;
	}
	.search{
		display: flex;
		justify-content: center;
		width: 750px;
		margin: 40px auto;
	}
	.search input{
		outline: none;
		padding: 2%;
		flex: 4;
		border:1px solid #d3d3d3;
	}
	.search button{
		border:1px solid #EA0029;
		flex: 1;
		background-color: #ea0029;
		color: #fff;
		border-radius: 0;
	}
	.title{
		text-align: center;
	}
	.title h3{
		margin: 20px 0;
	}

</style>
